// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GpsEnvironmentConfig } from "./environment-config";

export const gpsEnvironment: GpsEnvironmentConfig = {
  production: false,
  api: {
    'CARIAD': {
      url: '#{cariadGpsApiUrl}#',
      clientId: '#{cariadGpsClientId}#',
      authority: '#{cariadGpsAuthority}#',
      scopes: ['#{cariadGpsAuthScope}#']
    },
    'VWGPROD': {
      url: '#{vwgprodGpsApiUrl}#',
      clientId: '#{vwgprodGpsClientId}#',
      authority: '#{vwgprodGpsAuthority}#',
      scopes: ['#{vwgprodGpsAuthScope}#']
    },
    'VWGST': {
      url: '#{gstGpsApiUrl}#',
      clientId: '#{gstGpsClientId}#',
      authority: '#{gstGpsAuthority}#',
      scopes: ['#{gstGpsAuthScope}#']
    },
    'PowerCo': {
      url: '#{powercoGpsApiUrl}#',
      clientId: '#{powercoGpsClientId}#',
      authority: '#{powercoGpsAuthority}#',
      scopes: ['#{powercoGpsAuthScope}#']
    },
    'VWGITC': {
      url: '#{gitcGpsApiUrl}#',
      clientId: '#{gitcGpsClientId}#',
      authority: '#{gitcGpsAuthority}#',
      scopes: ['#{gitcGpsAuthScope}#']
    }
  }
};
