import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GpsLayoutLiteComponent } from '../layout/components/gps.layout-lite.component';
import { AadRoleAssignComponent } from './components/aad-role-assign.component';

const routes: Routes = [
  {
    path: 'aad-role-assign',
    pathMatch: 'full',/*canActivate: [MsalGuard],*/
    component: AadRoleAssignComponent
  },
  //{
  //  path: 'list', component: NpsLayoutComponent,
  //  children: [
  //    { path: '', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: NetworkRequestListComponent },
  //    { path: ':requestId', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: NetworkRequestEditComponent },
  //  ]
  //},
  {
    path: '',
    redirectTo: 'aad-role-assign',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IamRoutingModule { }
