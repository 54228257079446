import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { TenantKey } from '../../shared/models/tenant';
import { gpsEnvironment } from '../environments/environment';

const gpsStoreKey = 'gps.store';
interface GpsProps {
  user?: AccountInfo,
  //gpsUser?: UserMeDto ;
  tenant?: TenantKey;
  //permissions?: { [key: string]: RequestPermissionsType };
}

const gpsStore = createStore(
  { name: 'user' },
  withProps<GpsProps>({ })
);

//save in local storage
export const persist = persistState(gpsStore, {
  key: gpsStoreKey,
  storage: localStorageStrategy,
});

gpsStore.subscribe((state) => console.log(state));
//persist.initialized$.subscribe(console.log);

@Injectable({ providedIn: 'root' })
export class GpsStore {

  store$ = gpsStore;

  user$ = gpsStore.pipe(select((state) => state.user));
  tenant$ = gpsStore.pipe(select((state) => state.tenant));
  //gpsUser$ = gpsStore.pipe(select((state) => state.npsUser));

  constructor() {
  }

  get current(): GpsProps {
    return gpsStore.getValue();
  }

  get apiUrl(): string {
    return this.current.tenant ? gpsEnvironment.api[this.current.tenant]!.url : '';
  }

  setUser(user: AccountInfo | undefined) {
    gpsStore.update((state) => ({
      ...state,
      user
    }));
  }

  //setNpsUser(gpsUser: UserMeDto | undefined) {
  //  gpsStore.update((state) => ({
  //    ...state,
  //    gpsUser
  //  }));
  //}

  //setPermissions(permissions: { [key: string]: RequestPermissionsType } | undefined) {
  //  gpsStore.update((state) => ({
  //    ...state,
  //    permissions
  //  }));
  //}

  setTenant(tenant: TenantKey | undefined) {
    if (tenant && !gpsEnvironment.api[tenant])
      throw new Error(`There is no configuration for tenant ${tenant}`);

    gpsStore.update((state) => ({
      ...state,
      tenant
    }));
  }

  cleanUser() {
    console.log("GpsStore.clean")
    //leave token
    this.setUser(undefined);
    //this.setGpsUser(undefined);
  }
}
