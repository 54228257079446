
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../shared/services/notification.service';
import { GpsStore } from '../../../services/gps.store';

@Component({
  templateUrl: './aad-role-assign.component.html',
  styles: ['.p-autocomplete-panel {z-index: 1003!important;}']
})
export class AadRoleAssignComponent implements OnInit {

  form: FormGroup = this.fb.group({ });

  constructor(
    private fb: FormBuilder,
    //private userProjectMappingService: UserProjectMappingService,
    private notify: NotificationService,
    private gpsStore: GpsStore,
    private router: Router
  ) {
  }

  async ngOnInit() {

    //let mapping = new UserProjectMappingDto({
    //  projectId: 'unknown',
    //  userId: this.gpsStore.current.npsUser?.userId ?? '',
    //  email: this.gpsStore.current.npsUser?.email ?? '',
    //});

    let mapping = {};

    this.form = this.fb.group(mapping);

    this.form.patchValue(mapping);

    this.addValidators();
  }

  addValidators() {
    this.form.controls["projectId"].addValidators([Validators.required]);
    //  this.form.controls["currency"].addValidators([Validators.required, Validators.minLength(3), Validators.maxLength(3)]);
    //  this.form.controls["transactionGroup"].addValidators([Validators.required, Validators.maxLength(100)]);
    //  this.form.controls["description"].addValidators([Validators.required, Validators.maxLength(250)]);
  }

  save() {
      //this.userProjectMappingService.
      //  projectUserRegister(this.form.value)
      //  .subscribe(r => {
      //    if (r) {
      //      this.notify.success('Saved')
      //      this.router.navigateByUrl('/nps');
      //    } else {
      //      this.notify.error('Failed', r);
      //    }
      //  })
  }
}

