
<div class="surface-ground flex justify-content-center align-items-center mt-8">
  <div class="flex flex-column align-items-center justify-content-center">
    <div class="text-center mt-3">
      <p-panel #container header="Assign AAD Role" styleClass="inline-block max-w-1000 w-full text-left">

        <form [formGroup]="form" class="m-5">
          <div>
            <div class="field">
              <label for="projectId" class="block text-900 font-medium mb-2">AAD Role &amp; Permissions</label>
              <input id="projectId" formControlName="projectId" type="text" pInputText placeholder="unknown" class="w-full mb-3">
            </div>

            <!--<div class="field">
              <label for="email" class="block text-900 font-medium mb-2">Email *</label>
              <input id="email" formControlName="email" type="text" pInputText placeholder="Email" class="w-full mb-3">
            </div>-->
            <!--<div class="field">
              <label for="meshUsername" class="block text-900 font-medium mb-2">Mesh UserName</label>
              <input id="meshUsername" formControlName="meshUsername" type="text" pInputText placeholder="Mesh User Name" class="w-full mb-3">
            </div>-->

            <p>
              <i class="pi pi-spin pi-spinner"></i>
              Checking ownership and permissions...
            </p>

            <p>
              <i class="pi pi-spin pi-spinner"></i>
              Checking existing assignments...
            </p>

            <div class="text-center">
              <p-button label="Cancel" icon="pi pi-times" class="m-3" routerLink="../"></p-button>
              <p-button label="Assign" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
            </div>

          </div>
        </form>

      </p-panel>
    </div>
  </div>
</div>
